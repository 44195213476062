<template>
    <div class="popup">
        <div class="progress-bar">
            <div class="bar" :style="`width: ${progressBar}%`"></div>
        </div>
        <div class="wrapper">
            <div class="subtitle">
                Отправлено {{ initSteps }} из {{ finalSteps }} заявок
            </div>
            <p class="text">
                Получите персональное предложение с максимальной вероятностью
                одобрения
            </p>
            <div class="btn-wrapper">
                <a
                    class="btn"
                    :class="stepsClass"
                    :href="maxOfferLink"
                    target="_blank"
                    @click="getMaxOfferLink(notClicked)"
                >Оформить займ</a
                >
            </div>
        </div>
    </div>
</template>

<script>
import './ProgressBar.scss'

export default {
    props: ['initSteps', 'finalSteps', 'progressBar', 'notClicked'],
    data() {
        return {
            maxOfferLink: null,
        };
    },
    computed: {
        stepsClass() {
            return this.initSteps >= this.finalSteps ? 'active' : '';
        }
    },
    methods: {
        getMaxOfferLink(arr) {
            const maxLinkEl = [...arr].reduce((acc, curr) =>
                acc.chance > curr.chance ? acc : curr
            );
            this.maxOfferLink = maxLinkEl.link;
        },
    },
}
</script>
